import type { GlobalColors } from "@zero/types/ui/globals";

const globalColors: GlobalColors = {
	primary: "#141518",
	secondary: "#202124",
	text_primary: "#ffffff",
	text_secondary: "#f9f9f9",
};

export default globalColors;
